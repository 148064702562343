<script>
	// import { fade } from "svelte/transition";
	// import { addy } from "./stores.js";
	import axios from "axios";
	import { draw } from "svelte/transition";
	import { onMount } from "svelte";
	let blobs_left = new Array(3);
	let blobs_right = new Array(50);
	let isAuto = true;
	let addy_local;
	let addy_local_to;
	// const unsubscribe = addy.subscribe((value) => {
	//   console.log(value);
	//   addy_local = value;
	// });
	let workflow = "Storage - Yes";

	// Please edit as per env @atin
	// let Allingham = {
	// 	groupID: 1647449911,
	// 	eventTypeID: 1647449971,
	// 	eventType: "Jobs",
	// 	workflow: "Allingham",
	// };
	// let VaultMods = {
	// 	groupID: 1662652548,
	// 	eventTypeID: 1662653091,
	// 	eventType: "Jobs",
	// 	workflow: "Allingham - No mods",
	// };

	let Allingham = {
		groupID: 1673866908,
		eventTypeID: 1673888581,
		eventType: "Door to door jobs",
		workflow: "Tasks",
	};
	let VaultMods = {
		groupID: 1673866908,
		eventTypeID: 1673886623,
		eventType: "Storage jobs",
		workflow: "Tasks",
	};
	let contact_method = "email";
	export let reg_ex_mobile = /^[0]{1}[4]{1}[0-9]{8}$/; //0 426223349
	export let mobile_country = "+61";
	export let mobile_format = "04XX XXX XXX";
	export let mobile_placeholder = "0404567890";
	export let mobile_minlength = 10;
	export let mobile_maxlength = 10;
	export let addy_zip_format = 4;
	export let country_code;
	export let addy_auto;
	export let addy_auto_to;
	export let addy_all = {
		addy_street: "",
		addy_city: "",
		addy_state: "",
		addy_country: "",
		addy_zip: "",
	};
	export let selectedDate;
	export let begin_after;
	export let mobile;
	export let email;
	export let job_specific;
	export let notes = " ";

	export let manual_source = "";
	export let onsite_quote = "Onsite Quote - Yes";

	export let insurance = "";

	export let name_first;
	export let name_last;
	export let booking_modify;
	export let error_format = "border border-red-400";
	// export let error_addy_city;
	// export let error_addy_zip;
	// export let error_addy_state;
	// export let error_addy_street;

	// export let error_bk_date;
	export let error_begin_after;
	export let error_begin_date;
	export let error_email;
	export let error_mobile;
	export let error_name_first;
	export let error_name_last;
	export let error_address_from;
	export let error_address_to;
	export let token = "ok";
	export let job;
	//  export let password;
	export let loading = false;
	export let loading_success = false;
	export let loading_failure = false;
	export let jobs_sp_annual = [
		{
			id: 2,
			text: "Backflow",
			val: ["Plumbing Water", "Backflow", "Installation"],
		},

		{
			id: 6,
			text: "Fire Service",
			val: ["Plumbing Water", "Fire Service", "Annual Test"],
		},

		{ id: 13, text: "Other", val: ["Plumbing Water", "General", "Quote"] },
	];
	export let jobs_sp = [
		{
			id: 1,
			text: "Select",
			val: ["Plumbing Water", "General", "Quote"],
		},
		{
			id: 2,
			text: "Backflow",
			val: ["Plumbing Water", "Backflow", "Installation"],
		},
		{
			id: 3,
			text: "Check Meter",
			val: ["Plumbing Water", "Check Meter", "Upgrade"],
		},
		{
			id: 4,
			text: "Drain",
			val: ["Plumbing General", "Installation", "Fixture"],
		},
		{
			id: 5,
			text: "Hot water service",
			val: ["Plumbing Water", "Hot water service", "Installation"],
		},
		{
			id: 6,
			text: "Fire Service",
			val: ["Plumbing Water", "Fire Service", "Annual Test"],
		},
		{
			id: 7,
			text: "General",
			val: ["Plumbing General", "General", "Repair of leak"],
		},
		{
			id: 8,
			text: "Sink",
			val: ["Plumbing General", "Installation", "Fixture"],
		},
		{
			id: 9,
			text: "Shower",
			val: ["Plumbing General", "Installation", "Fixture"],
		},
		{
			id: 10,
			text: "Tap",
			val: ["Plumbing General", "Installation", "Fixture"],
		},
		{
			id: 11,
			text: "Toilet",
			val: ["Plumbing General", "Installation", "Fixture"],
		},
		{
			id: 12,
			text: "Water Pipe",
			val: ["Plumbing Water", "Water Pipe", "Repair of leak"],
		},
		{ id: 13, text: "Other", val: ["Plumbing Water", "General", "Quote"] },
	];

	let final = jobs_sp_annual.concat(jobs_sp);

	let currentDate = new Date();
	let minDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${
		currentDate.getDate() + 1
	}`;

	export let visited = {
		move: false,
		contact: false,
		review: false,
		finish: false,
	};

	export let activeStep = 1;

	export let picked = false;

	export let pickedId = "";

	// export let trucks = [
	//   {
	//     id: 1,
	//     name: "UTE(Small)",
	//     label_not_riyo_id: "company",
	//     img: "https://www.jbremovals.sydney/wp-content/uploads/2020/08/Truck-Package-2.png",
	//     price: "$23",
	//     weight: 1,
	//     res: 2,
	//     storage: "Few Item",
	//   },
	//   {
	//     id: 2,
	//     name: "Medium",
	//     label_not_riyo_id: "company",
	//     img: "https://www.jbremovals.sydney/wp-content/uploads/2020/08/truck.png",
	//     price: "$33",
	//     weight: 4.5,
	//     res: 2,
	//     storage: "1-2",
	//   },
	//   {
	//     id: 3,
	//     name: "Large",
	//     label_not_riyo_id: "company",
	//     img: "https://www.jbremovals.sydney/wp-content/uploads/2020/08/Truck-package-1.png",
	//     price: "$35.50",
	//     weight: 10,
	//     res: 2,
	//     storage: "2-3",
	//   },
	//   {
	//     id: 4,
	//     name: "X-Large",
	//     label_not_riyo_id: "company",
	//     img: "https://www.jbremovals.sydney/wp-content/uploads/2020/08/Truck-package-1.png",
	//     price: "$53",
	//     weight: 14,
	//     res: "3",
	//     storage: "5-6",
	//   },
	// ];

	export let show_success = false;

	export let source = "";
	export let sourceList = "";

	onMount(async () => {		
		signIn("alling_d92fzcba2lwyj@quicta.io", "vfvWPaaw8u7VQMjoXREynkPsbv"); // for prod		
	});
	function select_country() {
		console.log({ country_code: country_code });
		switch (country_code) {
			case "AU":
				reg_ex_mobile = /^[0]{1}[4]{1}[0-9]{8}$/; //0 426223349
				mobile_country = "+61";
				mobile_format = "04XX XXX XXX";
				mobile_placeholder = "0404567890";
				mobile_minlength = 10;
				mobile_maxlength = 10;
				addy_zip_format = 4;
				break;
			case "NZ":
				reg_ex_mobile = /^[0]{1}[2]{1}[0-9]{1}[0-9]{6}$/;
				mobile_country = "+64";
				mobile_format = "02XX XXX XX";
				mobile_placeholder = "020456789";
				mobile_minlength = 9;
				mobile_maxlength = 10;
				addy_zip_format = 4;

				break;
			case "SG":
				reg_ex_mobile = /^[8-9]{1}[0-9]{7}$/;
				mobile_country = "+65";
				mobile_format = "8XXX XXXX";
				mobile_placeholder = "82045678";
				mobile_minlength = 8;
				mobile_maxlength = 8;
				addy_zip_format = 6;
				break;

			case "PH":
				reg_ex_mobile = /^[0]{1}[8-9]{1}[0-9]{9}$/;
				mobile_country = "+63";
				mobile_format = "09XXXXXXXXX"; //+63 9 XXX XXX XXX
				mobile_placeholder = "09211111111";
				mobile_minlength = 11;
				mobile_maxlength = 11;
				addy_zip_format = 4;
				break;

			case "ID":
				reg_ex_mobile = /^[0]{1}[8]{1}[0-9]{2}[0-9]{8}$/;
				mobile_country = "+63";
				mobile_format = "8NNXXXXXXXX"; //+62 8nn XXXX XXXX
				mobile_placeholder = "082212345678";
				mobile_minlength = 12;
				mobile_maxlength = 12;
				addy_zip_format = 5;
				break;

			case "IND":
				reg_ex_mobile = /^[6-9]{1}[0-9]{9}$/;
				mobile_country = "+91";
				mobile_format = "9XXXXX XXXX";
				mobile_placeholder = "92045367834";
				mobile_minlength = 10;
				mobile_maxlength = 10;
				addy_zip_format = 6;
				break;
		}
	}
	function getMobileES164(input_mobile) {
		const country = country_code;

		const country_pfxs = {
			AU: "+61",
			NZ: "+64",
			IND: "+91",
			SG: "+65",
			PH: "+63",
			ID: "+62",
		};

		const pfx = country_pfxs[country];

		if (
			country_code == "AU" ||
			country_code == "NZ" ||
			country_code == "PH" ||
			country_code == "ID"
		) {
			return `${pfx}${input_mobile.substring(1)}`;
		} else {
			return `${pfx}${input_mobile}`;
		}
	}

	function formatLocation(locData, index, isdefault = false, location = "new") {
		return {
			location: location,
			_geo: {
				formatted_address: locData.formatted_address || "",
				_geoloc: locData._geoloc || {},
				place_id: locData.place_id || "",
				utc_offset: locData.utc_offset || 0,
			},
			AddressLine1: locData.address_line1,
			AddressLine2: locData.address_line2,
			Country: locData.country || "",
			type: index == 0 ? "client" : "",
			notes: "",
			isdefault: isdefault,
			index: index,
			time_in_min: 0,
		};
	}
	async function form_submit() {
		// console.log("form_submit");

		error_begin_date = "";
		error_begin_after = "";
		error_name_first = "";
		error_name_last = "";
		if (!selectedDate) {
			return (error_begin_date = error_format);
		}
		if (!begin_after) {
			return (error_begin_after = error_format);
		}

		if (!addy_local) {
			return (error_address_from = error_format);
		}
		if (!addy_local_to) {
			return (error_address_to = error_format);
		}

		if (!name_first) {
			return (error_name_first = error_format);
		}
		if (!name_last) {
			return (error_name_last = error_format);
		}
		select_country();

		if (!mobile) {
			return (error_mobile = error_format);
		} else {
			const re = /^[0]{1}[4]{1}[0-9]{8}$/;
			//  const v = re.test(String(mobile).toLowerCase());

			const v = reg_ex_mobile.test(String(mobile).toLowerCase());
			if (!v) {
				return (this.error_mobile = this.error_format);
			}
		}
		const mobileE164 = getMobileES164(mobile);
		if (!email) {
			return (error_email = error_format);
		} else if (email) {
			const re =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			const v = re.test(String(email).toLowerCase());
			if (!v) {
				return (error_email = error_format);
			}
		}
		// if (!addy_street) {
		//   return (error_addy_street = error_format);
		// }
		// if (!addy_city) {
		//   return (error_addy_city = error_format);
		// }
		// if (!addy_state) {
		//   return (error_addy_state = error_format);
		// }

		// if (!addy_zip || addy_zip.length !== 4) {
		//   return (error_addy_zip = error_format);
		// }

		const now = new Date();
		const now_string = now.toString();
		const beg_test = begin_after.split(":");
		const beg_nu = Number(beg_test[0]);
		const begin_hours_am_pm = await new Promise((res) => {
			if (beg_nu > 12) {
				const beg_sub = beg_nu - 12;
				res(`${beg_sub}:${beg_test[1]}:00 PM`);
			} else if (beg_nu == 12) {
				res(`12:${beg_test[1]}:00 PM`);
			} else {
				res(`${beg_nu}:${beg_test[1]}:00 AM`);
			}
		});
		const end_test = begin_after.split(":");
		const end_nu = Number(end_test[0]) + 1;
		const end_hours_am_pm = await new Promise((res) => {
			if (end_nu > 23) {
				res(`23:59:00 PM`);
			} else if (end_nu > 12) {
				const end_sub = end_nu - 12;
				res(`${end_sub}:${end_test[1]}:00 PM`);
			} else if (end_nu == 12) {
				res(`12:${end_test[1]}:00 PM`);
			} else {
				res(`${end_nu}:${end_test[1]}:00 AM`);
			}
		});
		const nw = Math.floor(Date.now() / 1000);
		const date_slash_pre = selectedDate.split("-");
		const date_slash = `${date_slash_pre[2]}/${date_slash_pre[1]}/${date_slash_pre[0]}`;
		// console.log(`${date_slash} ${begin_hours_am_pm} a`);
		// console.log(`${date_slash} ${end_hours_am_pm} b`);
		let job_final;
		// console.log({ job_specific });
		for (const job of final) {
			if (job_specific == job.id) {
				job_final = job;
			}
		}
		// console.log({ job_final });
		// console.log({ addy_local });
		if (addy_local.addy) {
			addy_auto = addy_local.addy;

			let place = addy_local.place;
			// addy_street: null,
			//       addy_zip: null,
			//       addy_city: null,
			//       addy_country: "",
			//       addy_state: null,
			const address_map = {
				street_number: "addy_street",
				route: "addy_street",
				locality: "addy_street",
				administrative_area_level_2: "addy_city",
				administrative_area_level_1: "addy_state",
				country: "addy_country",
				postal_code: "addy_zip",
			};
			const addy_fields = Object.values(address_map);
			for (var i = 0; i < addy_fields.length; i++) {
				var var_name = addy_fields[i];
				if (addy_all[var_name]) addy_all[var_name] = "";
			}
			const setflags = {
				addy_street: false,
				addy_city: false,
				addy_state: false,
				addy_country: false,
				addy_zip: false,
			};

			for (var i = 0; i < place.length; i++) {
				var addressType = place[i].types[0];
				if (address_map[addressType]) {
					const var_name = address_map[addressType];
					const val = place[i]["long_name"];
					if (setflags[var_name]) {
						addy_all[var_name] += `,${val}`;
					} else {
						addy_all[var_name] = val;
						setflags[var_name] = true;
					}
				}
			}
			// console.log(`${addy_all.addy_street},
			//  ${addy_all.addy_zip},
			//  ${addy_all.addy_city},
			//  ${addy_all.addy_country},
			//  ${addy_all.addy_state}`);

			addy_auto = {
				...addy_auto,
				...{
					address_line1: addy_all.addy_street,
					address_line2: "",
					city: addy_all.addy_city,
					state: addy_all.addy_state,
					postal_code: addy_all.addy_zip,
					country: addy_all.addy_country,
				},
			};
			// console.log(this.addy_auto);
		}
		if (addy_local_to.addy) {
			addy_auto_to = addy_local_to.addy;

			let addy_local_to_all = {
				addy_street: "",
				addy_city: "",
				addy_state: "",
				addy_country: "",
				addy_zip: "",
			};

			let place = addy_local_to.place;
			// addy_street: null,
			//       addy_zip: null,
			//       addy_city: null,
			//       addy_country: "",
			//       addy_state: null,
			const address_map = {
				street_number: "addy_street",
				route: "addy_street",
				locality: "addy_street",
				administrative_area_level_2: "addy_city",
				administrative_area_level_1: "addy_state",
				country: "addy_country",
				postal_code: "addy_zip",
			};
			const addy_fields = Object.values(address_map);
			for (var i = 0; i < addy_fields.length; i++) {
				var var_name = addy_fields[i];
				if (addy_local_to_all[var_name]) addy_local_to_all[var_name] = "";
			}
			const setflags = {
				addy_street: false,
				addy_city: false,
				addy_state: false,
				addy_country: false,
				addy_zip: false,
			};

			for (var i = 0; i < place.length; i++) {
				var addressType = place[i].types[0];
				if (address_map[addressType]) {
					const var_name = address_map[addressType];
					const val = place[i]["long_name"];
					if (setflags[var_name]) {
						addy_local_to_all[var_name] += `,${val}`;
					} else {
						addy_local_to_all[var_name] = val;
						setflags[var_name] = true;
					}
				}
			}
			// console.log(`${addy_all.addy_street},
			//  ${addy_all.addy_zip},
			//  ${addy_all.addy_city},
			//  ${addy_all.addy_country},
			//  ${addy_all.addy_state}`);

			addy_auto_to = {
				...addy_auto_to,
				...{
					address_line1: addy_local_to_all.addy_street,
					address_line2: "",
					city: addy_local_to_all.addy_city,
					state: addy_local_to_all.addy_state,
					postal_code: addy_local_to_all.addy_zip,
					country: addy_local_to_all.addy_country,
				},
			};
			// console.log(this.addy_auto);
		} else {
			addy_local_to_all.addy_auto = null;
			addy_local_to_all.addy_street = null;
			addy_local_to_all.addy_zip = null;
			addy_local_to_all.addy_city = null;
			addy_local_to_all.addy_country = null;
			addy_local_to_all.addy_state = null;
		}
		// let labelId = trucks[pickedId] ? [trucks[pickedId].label_not_riyo_id] : [];
		// debugger;
		const preferences = onsite_quote ? [onsite_quote] : [];
		const storage_prefs = workflow ? [workflow] : [];
		const insurance_selected = insurance ? [insurance] : [];
		const all_prefs = [...preferences, ...storage_prefs, ...insurance_selected];
		booking_modify = {
			// shape: "shapeRiyo",
			Duration: 3600,
			Tolerance: 120,
			JobNo: `online`, //:"869381",  done
			TaskNo: `${nw}`, //:"1011507", ?
			CreateDate: "25/02/2020 11:39:37 AM", //:"25/02/2020 11:39:37 AM",
			JobStatus: "", //:"Open ",
			Priority: "8", //:"8",  ?
			MustStartBy: `${date_slash} ${begin_hours_am_pm}`, //:"2/03/2020 8:00:00 AM", done
			MustFinishBy: `${date_slash} ${end_hours_am_pm}`, //:"2/03/2020 10:00:00 AM", done
			WorkCompleteTime: `${date_slash} ${end_hours_am_pm}`, //:"1/01/0001 12:00:00 AM", ?
			EmployeeName: "Riyo", //:"Debbie Murray", ignore
			CreatedBy: "Riyo", //:"SEe0209",
			CallerName: `${name_first} ${name_last}`, //:" Rushmini Perera",
			CallerPhoneNo: mobileE164, //:"0417770333", lookup
			CallerEmail: email, //:"rushminip@hotmail.com",
			CCT: all_prefs, // job_final.val[0], // "", // job_final.val[0], //`${job_final.val[0]} ${job_final.val[1]} ${job_final.val[2]}`, //:"Plumbing Water-Check Meter-Quote",
			Address: addy_auto, //`${addy_street}, ${addy_city}, ${addy_state}, ${addy_zip}, Australia`,
			AddressLine1: addy_all.addy_street,
			AddressLine2: "",
			City: addy_all.addy_city,
			State: addy_all.addy_state,
			PostalCode: addy_all.addy_zip,
			CustNum: [],
			Country: addy_all.addy_country,
			Contractor: "", //:"South East Water", ignore
			Department: "", //:"SEW PriorityPlumbing", ignore
			Category: [], //job_final.val[0], //:"Plumbing Water", label
			Class: [], //:"Check Meter",  label
			Type: [], //:"Quote", label
			TaskStory: "", //:"[Murray,Debbie] [25/2/2020 11:39] [ALLOCATED]~Task Created,[Murray,Debbie] [25/2/2020 11:39] [ALLOCATED]~Property Number:36C//08162/53,[OT ] [Murray,Debbie] [25/2/2020 11:39] [ALLOCATION]\nTask Allocated to a Field Terminal : SEf0009,[Recall Reason] [Sharlassian,Pam] [26/2/2020 09:12] [ALLOCATED]~.,[OT ] [Sharlassian,Pam] [26/2/2020 09:12] [TASK RECALL]\nTask Recalled from the Field Terminal : SEf0009,[OT ] [Sharlassian,Pam] [26/2/2020 09:13] [ALLOCATION]\nTask Allocated to a Field Terminal : SEf0005,[Sharlassian,Pam] [2/3/2020 11:57] [ALLOCATED]~Quote emailed to customer \nQuote Fee Paid $60.00\nEFT-attached \nA-923347\n,[Sharlassian,Pam] [3/3/2020 07:53] [ALLOCATED]~CS17733\nAUTH 923347",
			ProblemDetail: "", //:addy_auto_to.formatted_address"Customer adv she wants a quote to move her Check Meter Aware Quote fee Booked 2-4"
			TaskDetail: "", //"e: info@morningtonmed.com.au\nC:Jill fulton\nP:59752633\nAnnual Back Flow Test \nPleae invoice customer \nLP booked 27/3/2020@8-10am\n",
			PrimaryAllocateeList: "Riyo", //"SEf0005"
			source: source ? source : manual_source,

			contact_method: contact_method || "email",
			ContactMethod: contact_method,
			all_location: [
				formatLocation(addy_auto, 0, true, "from_addr"),
				formatLocation(addy_auto_to, 1),
			],
			AllAddresses: [
				formatLocation(addy_auto, 0, true, "from_addr"),
				formatLocation(addy_auto_to, 1),
			],
		};
		if (workflow && workflow === "Storage - Yes") {
			booking_modify = { ...booking_modify, ...Allingham };
		}
		if (workflow && workflow === "Storage - No") {
			booking_modify = { ...booking_modify, ...VaultMods };
		}
		console.log({ booking_modify });
		sendBooking();
	}

	function signIn(em, pw) {
		// loading = true;

		const public_key_v4 = "AIzaSyD9Z2S9xP-gLLukZrXFZax-vJFbFe83jyI"; // for prod
		// const public_key_v4 = "AIzaSyCSygyZocn2m_YKCz-G5-Z9AskT5njpF-A"; // riyo dev au
		const payload = {
			email: em, //email, //"public+sew@riyo.io",
			password: pw, //password, // "asdfgh",
			returnSecureToken: true,
		};
		// debugger;
		axios
			.post(
				`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${public_key_v4}`,
				payload
			)
			.then((res) => {
				// check here for response object https://firebase.google.com/docs/reference/rest/auth#section-create-email-password
				// console.log({ res });
				// debugger;
				token = res.data.idToken;
				// console.log(token);
				// loading = false;
				// loading_success = true;
				// setTimeout(() => {
				//   loading = false;
				//   loading_success = false;
				// }, 2000);
				retriveSource();
			})
			.catch((e) => {
				// console.log({ e });
				loading = false;
			});
	}
	function clearForm() {
		selectedDate = null;
		begin_after = null;
		mobile = null;
		email = null;
		job_specific = null;
		job = null;
		notes = " ";
		// addy_city = null;
		// addy_state = null;
		// addy_street = null;
		// addy_zip = null;
		name_first = null;
		name_last = null;
		booking_modify = null;
		pickedId = "";
		picked = false;
		addy_auto = "";
		addy_auto_to = "";
		addy_local = "";
		addy_local_to = "";
		setTimeout(() => {
			visited = {
				move: false,
				contact: false,
				review: false,
				finish: false,
			};
			show_success = false;
			activeStep = 1;
		}, 5000);
	}
	function sendBooking() {
		// console.log({ token });
		loading = true;
		if (!token) {
			return;
		}
		const endpoint = "https://australia-southeast1-riyo-v3.cloudfunctions.net/"; // for prod
		// const endpoint =
		// "https://australia-southeast1-riyo-dev-au.cloudfunctions.net/"; // for riyo dev

		// axios.setHeader("Authorization", `Bearer ${token}`);
		const instance = axios.create({
			baseURL: `${endpoint}`,
			timeout: 20000,
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
			},
		});
		//    await this.$axios.setHeader("Access-Control-Allow-Origin", "*");
		console.log({ booking_modify });

		return instance
			.post(`api_au/task_widget`, booking_modify)
			.then((response) => {
				// response.data.objects;
				// console.log({ response });
				show_success = true;
				loading_success = true;
				setTimeout(() => {
					loading_success = false;
					loading = false;
					clearForm();
				}, 6000);
			})
			.catch((e) => {
				show_success = false;
				loading_failure = true;
				setTimeout((_) => {
					loading_failure = false;
					loading = false;
					clearForm();
				}, 3000);
				// console.log({ e });
				// if (e.response) {
				//   alert(e.response.data.message);
				// }
				// if (e.message) {
				//   alert(e.message);
				// }
			});
	}

	function moveNext(nextStep, lastStep) {
		if (nextStep === 2) {
			if (!selectedDate) {
				return (error_begin_date = error_format);
			}
			if (!begin_after) {
				return (error_begin_after = error_format);
			}

			if (!addy_local) {
				return (error_address_from = error_format);
			}
			if (!addy_local_to) {
				return (error_address_to = error_format);
			}
		}
		if (nextStep === 3) {
			if (!name_first) {
				return (error_name_first = error_format);
			}
			if (!name_last) {
				return (error_name_last = error_format);
			}
			select_country();

			if (!mobile) {
				return (error_mobile = error_format);
			} else {
				const re = /^[0]{1}[4]{1}[0-9]{8}$/;
				//  const v = re.test(String(mobile).toLowerCase());

				const v = reg_ex_mobile.test(String(mobile).toLowerCase());
				if (!v) {
					return (error_mobile = error_format);
				}
			}

			if (!email) {
				return (error_email = error_format);
			} else if (email) {
				const re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const v = re.test(String(email).toLowerCase());
				if (!v) {
					return (error_email = error_format);
				}
			}
		}
		if (nextStep === 4) {
			form_submit();
		}
		activeStep = nextStep;
		visited[lastStep] = true;
	}

	async function retriveSource() {
    try {
      const queryString = window.location.search;
      console.log(queryString);
      let urlParams = new URLSearchParams(queryString);
      let code = urlParams.get("source");

      if (!token) {
        return;
      }
      const endpoint =
        "https://australia-southeast1-riyo-v3.cloudfunctions.net/"; // for prod

      // const endpoint =
      //   "https://australia-southeast1-riyo-dev-au.cloudfunctions.net/"; // for riyo dev

      // axios.setHeader("Authorization", `Bearer ${token}`);
      const instance = axios.create({
        baseURL: `${endpoint}`,
        timeout: 20000,
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (code) {
        let resp = await instance.post(`api_au/get_source`, {
          code: code,
        });
        source = resp && resp.data && resp.data.source;
      } else {
        let resp = await instance.post(`api_au/get_sources`);

        sourceList =
          resp && resp.data && resp.data && resp.data.length ? resp.data : [];
        console.log("==========================", console.log("resp", resp));
        console.log(
          "==========================",
          console.log("sourceList", sourceList)
        );
      }
    } catch (err) {
      console.log("err", err);
    }
	}

	import Map from "./Map.svelte";
	export let ready;
</script>

<svelte:head>
	<script
		defer
		async
		src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD9Z2S9xP-gLLukZrXFZax-vJFbFe83jyI&libraries=places&callback=initMap"
	>
	</script>
</svelte:head>
<div class="bg-white text-white flex" />

<div class="w-full px-10 py-10 flex items-end justify-between bg-black">
	<img class="-mb-2 ml-2" src="./images/allingham_logo.png" alt="Allingham" style="margin-bottom: -2.5rem;" />
	<div class=" lg:flex lg:items-center lg:justify-between">
		<div class="flex flex-row text-white-300">
			<span class="relative z-0 inline-flex hidden">
				<p
					style=" font-family: 'Gloria Hallelujah', cursive; color:#9f9f9f"
					class="px-3"
				>
					Connect with us:
				</p>

				<button
					type="button"
					class=" relative inline-flex items-center px-1 hidden"
				>
					<a
						class=""
						target="_blank"
						href="https://www.facebook.com/riyo.platform"
						title="Facebook"
					>
						<img src="/images/facebook-icon.png" alt="Facebook" />
					</a>
				</button>
				<button
					type="button"
					class=" relative inline-flex items-center px-3 hidden"
				>
					<a
						class="
            "
						target="_blank"
						href="https://www.twitter.com/riyo_platform"
						title="Twitter"
					>
						<img src="/images/twitter-icon.png" alt="Twitter" />
					</a>
				</button>
			</span>
		</div>
	</div>
</div>

<div class="w-full h-2 -mt-2" />
<div class="flex bg-black text-white overflow-hidden py-3">
	<div class="h-2 -mt-1 flex flex-none">
		{#each blobs_left as left}
			<div id="blb" class=" flex justify-center items-center">
				<div class="bg-black rounded-sm w-3 h-3" />
			</div>
		{/each}

		<div id="blb" class=" flex justify-center items-center">
			<div class="bg-black rounded-sm w-3 h-3" />
		</div>

		{#each blobs_right as rgt}
			<div id="blb" class=" flex justify-center items-center">
				<div class="bg-black rounded-sm w-3 h-3" />
			</div>
		{/each}
	</div>
</div>
<div
	class="w-full h-2 -mt-2 bg-repeat"
	style="background-image:url(images/header-bottom-border.png)"
/>
{#if loading}
	<div
		class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex
    sm:items-center sm:justify-center"
		style="z-index:1000"
	>
		<div class="fixed inset-0 transition-opacity">
			<div class="absolute inset-0 bg-gray-500 opacity-75" />
			<div
				class="transition duration-150 ease-in-out h-12 w-12 mx-auto relative"
				style="top: 50%"
			>
				<svg
					version="1.1"
					id="L2"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					enable-background="new 0 0 100 100"
					xml:space="preserve"
				>
					<circle
						fill="none"
						stroke="#fff"
						stroke-width="4"
						stroke-miterlimit="10"
						cx="50"
						cy="50"
						r="48"
					/>
					<line
						fill="none"
						stroke-linecap="round"
						stroke="#fff"
						stroke-width="4"
						stroke-miterlimit="10"
						x1="50"
						y1="50"
						x2="85"
						y2="50.5"
					>
						<animateTransform
							attributeName="transform"
							dur="2s"
							type="rotate"
							from="0 50 50"
							to="360 50 50"
							repeatCount="indefinite"
						/>
					</line>
					<line
						fill="none"
						stroke-linecap="round"
						stroke="#fff"
						stroke-width="4"
						stroke-miterlimit="10"
						x1="50"
						y1="50"
						x2="49.5"
						y2="74"
					>
						<animateTransform
							attributeName="transform"
							dur="15s"
							type="rotate"
							from="0 50 50"
							to="360 50 50"
							repeatCount="indefinite"
						/>
					</line>
				</svg>
			</div>
		</div>
		{#if loading_success}
			<div
				class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl
        transform transition-all sm:max-w-sm sm:w-full sm:p-6"
				role="dialog"
				aria-modal="true"
				aria-labelledby="modal-headline"
			>
				<div>
					<div
						class="mx-auto flex items-center justify-center h-12 w-12
            rounded-full bg-green-100"
					>
						<svg
							class="h-6 w-6 text-green-600"
							stroke="currentColor"
							fill="none"
							viewBox="0 0 24 24"
						>
							<path
								in:draw={{ duration: 1000, delay: 0 }}
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M5 13l4 4L19 7"
							/>
						</svg>
					</div>
					<div class="mt-3 text-center sm:mt-5">
						<h3
							class="text-lg leading-6 font-medium text-gray-900"
							id="modal-headline"
						>
            Enquiry Submitted
						</h3>
						<div class="mt-2">
							<p class="text-sm leading-5 text-gray-500">
								Please check your preferred contact method for more information. We'll be in touch shortly. Thanks
							</p>
						</div>
					</div>
				</div>
			</div>
		{/if}
		{#if loading_failure}
			<div
				class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl
        transform transition-all sm:max-w-sm sm:w-full sm:p-6"
				role="dialog"
				aria-modal="true"
				aria-labelledby="modal-headline"
			>
				<div>
					<div
						class="mx-auto flex items-center justify-center h-12 w-12
            rounded-full bg-red-100"
					>
						<svg
							class="h-6 w-6 text-red-600"
							stroke="currentColor"
							fill="none"
							viewBox="0 0 20 20"
						>
							<path
								in:draw={{ duration: 1000, delay: 0 }}
								stroke-width="2"
								d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711
                4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166
                3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071
                5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976
                15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929
                15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342
                16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289
                14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237
                4.68342 4.29289 4.29289Z"
							/>
						</svg>
					</div>
					<div class="mt-3 text-center sm:mt-5">
						<h3
							class="text-lg leading-6 font-medium text-gray-900"
							id="modal-headline"
						>
							Booking failed
						</h3>
						<div class="mt-2">
							<p class="text-sm leading-5 text-gray-500">
								Our apologies. Please check your internet and try again.
							</p>
						</div>
					</div>
				</div>
			</div>
		{/if}
	</div>
{/if}

<div class="bg-white-300 px-5 sm:px-20 pt-5 pb-10">
	<form
		id="online_booking"
		method="POST"
		on:submit|preventDefault={form_submit}
		class=""
	>
		{#if token.length > 0}
			<div class="md:grid md:grid-cols-2 md:gap-6">
				<div class="mt-5 md:col-span-2 py-5 px-5 bg-white shadow rounded">
					<!-- @submit="form_submit" -->

					<div class="p-5">
						<div class="mx-4 p-4">
							<div class="flex items-center">
								<div
									class="flex items-center relative {activeStep === 1
										? 'text-white'
										: 'text-teal-600'}"
								>
									<div
										class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 {activeStep ===
										1
											? 'bg-teal-600 border-teal-600'
											: visited.move
											? 'border-teal-600'
											: null}"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="100%"
											height="100%"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="feather feather-bookmark"
										>
											<path
												d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
											/>
										</svg>
									</div>
									<div
										class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600"
									>
										Your Move
									</div>
								</div>
								<div
									class="flex-auto border-t-2 transition duration-500 ease-in-out {visited.move
										? 'border-teal-600'
										: 'border-gray-300'}"
								/>
								<div
									class="flex items-center relative {activeStep === 2
										? 'text-white'
										: 'text-teal-600'}"
								>
									<div
										class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 {activeStep ===
										2
											? 'bg-teal-600 border-teal-600'
											: visited.contact
											? 'border-teal-600'
											: null}"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="100%"
											height="100%"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="feather feather-user-plus"
										>
											<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
											<circle cx="8.5" cy="7" r="4" />
											<line x1="20" y1="8" x2="20" y2="14" />
											<line x1="23" y1="11" x2="17" y2="11" />
										</svg>
									</div>
									<div
										class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600"
									>
										Contact
									</div>
								</div>
								<div
									class="flex-auto border-t-2 transition duration-500 ease-in-out {visited.contact
										? 'border-teal-600'
										: 'text-white'}"
								/>
								<div
									class="flex items-center relative {activeStep === 3
										? 'text-white'
										: 'text-teal-600'}"
								>
									<div
										class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 {activeStep ===
										3
											? 'bg-teal-600 border-teal-600'
											: visited.review
											? 'border-teal-600'
											: null}"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="100%"
											height="100%"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
											class="feather feather-mail"
										>
											<path
												d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
											/>
											<polyline points="22,6 12,13 2,6" />
										</svg>
									</div>
									<div
										class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
									>
										Confirm
									</div>
								</div>
								<!-- <div
                class="flex-auto border-t-2 transition duration-500 ease-in-out {visited.review
                  ? 'border-teal-600'
                  : 'border-gray-300'}"
              /> -->
								<!-- <div
                  class="flex items-center relative {activeStep === 4
                    ? 'text-white'
                    : 'text-teal-600'}"
                >
                  <div
                    class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 {activeStep ===
                    4
                      ? 'bg-teal-600 border-teal-600'
                      : visited.finish
                      ? 'border-teal-600'
                      : null}"
                  >
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-database"
                  >
                    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                  </svg>
                  </div>
                  <div
                    class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
                  >
                    Finished
                  </div>
                </div> -->
							</div>
						</div>
						{#if activeStep === 1}
							<div class="mt-8 p-4">
								<div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Move Date
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<input
													bind:value={selectedDate}
													min={minDate}
													placeholder="21/01/2020"
													id="select_date"
													type="date"
													class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
													on:change={(e) => (error_begin_date = "")}
												/>
											</div>
											{#if error_begin_date}
												<p class="mt-2 text-sm text-red-600">
													You must submit a date.
												</p>
											{/if}
										</div>
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Move Time
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<input
													bind:value={begin_after}
													min={minDate}
													placeholder="10:00"
													id="startBy"
													type="time"
													pattern="[0-2]&#123;1}[0-9]&#123;1}[:]&#123;1}[0-5]&#123;1}[0]&#123;1}"
													class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
													on:change={(e) => (error_begin_after = "")}
												/>
											</div>
											{#if error_begin_after}
												<p class="mt-2 text-sm text-red-600">
													You must submit a appointment start time. For example
													13:00 or 1:00 pm
												</p>
											{/if}
										</div>
									</div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full mx-2 flex-1 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Moving From
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												{#if ready}
													<Map
														value={addy_local && addy_local.text}
														on:place_info={(e) => {
															addy_local = e.detail;
															error_address_from = "";
														}}
													/>
												{/if}
											</div>
											{#if error_address_from}
												<p class="mt-2 text-sm text-red-600">
													You must submit a from address
												</p>
											{/if}
										</div>
										<div class="w-full mx-2 flex-1 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Moving To
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												{#if ready}
													<Map
														value={addy_local_to && addy_local_to.text}
														on:place_info={(e) => {
															addy_local_to = e.detail;
															error_address_to = "";
														}}
													/>
												{/if}
											</div>
											{#if error_address_to}
												<p class="mt-2 text-sm text-red-600">
													You must submit a to address
												</p>
											{/if}
										</div>
									</div>
									<div class="flex flex-col md:flex-row">
										<div
											class="w-full mx-2 flex-2 svelte-1l8159u"
											style="flex:0.5"
										>
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Do you require storage?
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<select
													class="p-1 px-2 outline-none w-full text-gray-800"
													bind:value={workflow}
													title="If yes this will trigger the workflow for Allingham; If no this will trigger the workflow for Allingham - No Mods"
												>
													<option value={"Storage - Yes"}>Yes</option>
													<option value={"Storage - No"}>No</option>
												</select>
											</div>
										</div>

										{#if !source}
											<div
												class="w-full mx-2 flex-2 svelte-1l8159u"
												style="flex:0.5"
											>
												<div
													class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
												>
													Where did you hear about us
												</div>
												<div
													class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
												>
													<select
														class="p-1 px-2 outline-none w-full text-gray-800"
														bind:value={manual_source}
														title="If yes this will provide additional information"
													>
														<option value="" />
                            {#each sourceList as source}
                            <option value={source.source}
                              >{source.source}</option
                            >
                          {/each}
													</select>
												</div>
											</div>
										{/if}
									</div>
									<div class="flex flex-col md:flex-row">
										<div
											class="w-full mx-2 flex-2 svelte-1l8159u"
											style="flex:0.5"
										>
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Would you like an onsite quote? (it's complimentary
												service)
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<select
													class="p-1 px-2 outline-none w-full text-gray-800"
													bind:value={onsite_quote}
													title="If yes this will trigger the workflow for Allingham; If no this will trigger the workflow for Allingham - No Mods"
												>
													<option value={"Onsite Quote - Yes"}>Yes</option>
													<option value={"Onsite Quote - No"}>No</option>
												</select>
											</div>
										</div>

										<!-- insurance -->
										<div
											class="w-full mx-2 flex-2 svelte-1l8159u"
											style="flex:0.5"
										>
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Please select the insurance that you require
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<select
													class="p-1 px-2 outline-none w-full text-gray-800"
													bind:value={insurance}
													title="If yes this will trigger the workflow for Allingham; If no this will trigger the workflow for Allingham - No Mods"
												>
													<option value="">Select Insurance</option>
													<option value={"ti"}>Transit Insurance</option>
													<option value={"si"}>Storage Insurance</option>
													<option value={"tandsi"}
														>Transit and Storage Insurance</option
													>
													<option value={"ni"}>No Insurance</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div class="flex p-2 mt-4">
									<div class="flex-auto flex flex-row-reverse">
										<button
											class="text-base ml-2 hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer
                  hover:bg-teal-600
                  bg-teal-600
                  text-teal-100
                  border duration-200 ease-in-out
                  border-teal-600 transition"
											on:click={(e) => moveNext(2, "move")}>Next</button
										>
									</div>
								</div>
							</div>
						{/if}
						{#if activeStep === 2}
							<div class="mt-8 p-4">
								<div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												First Name
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<input
													bind:value={name_first}
													placeholder="Your First Name"
													id="first_name"
													class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
													on:change={(e) => (error_name_first = "")}
												/>
											</div>
											{#if error_name_first}
												<p class="mt-2 text-sm text-red-600">
													You must submit first name.
												</p>
											{/if}
										</div>
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Last Name
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<input
													bind:value={name_last}
													placeholder="Your Last Name"
													id="last_name"
													class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
													on:change={(e) => (error_name_last = "")}
												/>
											</div>
											{#if error_name_last}
												<p class="mt-2 text-sm text-red-600">
													You must submit last name.
												</p>
											{/if}
										</div>
									</div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full mx-2 flex-1 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Phone
											</div>
											<div
												class="bg-white my-2 p-1 flex rounded border border-gray-200 svelte-1l8159u"
											>
												<div class="rounded-l flex items-center">
													<select
														aria-label="Country"
														class="form-select h-full py-0 pl-3 pr-7 border-transparent
                              bg-transparent text-gray-500 sm:text-sm sm:leading-5"
														bind:value={country_code}
														on:change={(e) => select_country(e.target.value)}
													>
														<option>AU</option>
														<option>NZ</option>
														<option>SG</option>
														<option>PH</option>
														<option>ID</option>
														<option>IND</option>
													</select>
												</div>

												<input
													maxlength={mobile_maxlength}
													id="phone_number"
													class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
													placeholder={mobile_placeholder}
													bind:value={mobile}
													on:change={(e) => (error_mobile = "")}
												/>
											</div>
											{#if error_mobile}
												<p class="mt-2 text-sm text-red-600">
													You must submit a valid contact number.
												</p>
											{/if}
										</div>
										<div class="w-full mx-2 flex-1 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Email
											</div>
											<div
												class="bg-white my-2 p-1 flex rounded border border-gray-200 svelte-1l8159u"
											>
												<input
													bind:value={email}
													id="email"
													placeholder="Your Email"
													type="email"
													class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
													on:change={(e) => (error_email = "")}
												/>
											</div>
											{#if error_email}
												<p class="mt-2 text-sm text-red-600">
													You must submit a valid email.
												</p>
											{/if}
										</div>
									</div>
									<div class="flex flex-col md:flex-row">
										<div
											class="w-full mx-2 flex-2 svelte-1l8159u"
											style="flex:0.5"
										>
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Contact method
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<select
													class="p-1 px-2 outline-none w-full text-gray-800"
													bind:value={contact_method}
													title="Contact method to receive notification"
												>
													<option value={"sms"}>SMS</option>
													<option value={"email"}>Email</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div class="flex p-2 mt-4">
									<button
										class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer
                hover:bg-gray-200
                bg-gray-100
                text-gray-700
                border duration-200 ease-in-out
                border-gray-600 transition"
										on:click={(e) => {
											activeStep = 1;
											visited.move = false;
										}}>Previous</button
									>
									<div class="flex-auto flex flex-row-reverse">
										<button
											class="text-base ml-2 hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer
                hover:bg-teal-600
                bg-teal-600
                text-teal-100
                border duration-200 ease-in-out
                border-teal-600 transition"
											on:click={(e) => moveNext(3, "contact")}>Next</button
										>
									</div>
								</div>
							</div>
						{/if}
						{#if activeStep === 3}
							<div class="mt-8 p-4">
								<div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-sm leading-8 capitalize h-6 mx-2 mt-3"
											>
												Move Details:
											</div>
										</div>
									</div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Move Date
											</div>
											<input
												bind:value={selectedDate}
												min={minDate}
												placeholder="21/01/2020"
												id="select_date"
												type="date"
												class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
												readonly="true"
												on:change={(e) => (error_begin_date = "")}
											/>
										</div>
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Move Time
											</div>
											<input
												bind:value={begin_after}
												min={minDate}
												placeholder="10:00"
												id="startBy"
												type="time"
												pattern="[0-2]&#123;1}[0-9]&#123;1}[:]&#123;1}[0-5]&#123;1}[0]&#123;1}"
												class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
												readonly="true"
												on:change={(e) => (error_begin_after = "")}
											/>
										</div>
									</div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full mx-2 flex-1 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Moving From
											</div>
											<input
												bind:value={addy_local.addy.formatted_address}
												placeholder="Moving From"
												id="first_name"
												class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
												readonly="true"
											/>
										</div>
										<div class="w-full mx-2 flex-1 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Moving To
											</div>
											<input
												bind:value={addy_local_to.addy.formatted_address}
												placeholder="Moving To"
												id="first_name"
												class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
												readonly="true"
											/>
										</div>
									</div>
									<div class="flex flex-col md:flex-row">
										<div
											class="w-full mx-2 flex-2 svelte-1l8159u"
											style="flex:0.5"
										>
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Do you require storage?
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<select
													class="p-1 px-2 outline-none w-full text-gray-800"
													bind:value={workflow}
													title="If yes this will trigger the workflow for Allingham; If no this will trigger the workflow for Allingham - No Mods"
												>
													<option value={"Storage - Yes"}>Yes</option>
													<option value={"Storage - No"}>No</option>
												</select>
											</div>
										</div>

										<div
											class="w-full mx-2 flex-2 svelte-1l8159u"
											style="flex:0.5"
										>
											<div
												class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
											>
												Would you like an onsite quote? (it's complimentary
												service)
											</div>
											<div
												class="bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u"
											>
												<select
													class="p-1 px-2 outline-none w-full text-gray-800"
													bind:value={onsite_quote}
													title="If yes this will trigger the workflow for Allingham; If no this will trigger the workflow for Allingham - No Mods"
												>
													<option value={"Onsite Quote - Yes"}>Yes</option>
													<option value={"Onsite Quote - No"}>No</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="mt-8 p-4">
								<div class="flex flex-col md:flex-row">
									<div class="w-full flex-1 mx-2 svelte-1l8159u">
										<div
											class="font-bold text-gray-600 text-sm leading-8 capitalize h-6 mx-2 mt-3"
										>
											Contact Details:
										</div>
									</div>
								</div>
								<div class="flex flex-col md:flex-row">
									<div class="w-full flex-1 mx-2 svelte-1l8159u">
										<div
											class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
										>
											First Name
										</div>
										<input
											bind:value={name_first}
											placeholder="Your First Name"
											id="first_name"
											class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
											on:change={(e) => (error_name_first = "")}
											readonly="true"
										/>
									</div>
									<div class="w-full flex-1 mx-2 svelte-1l8159u">
										<div
											class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
										>
											Last Name
										</div>
										<input
											bind:value={name_last}
											placeholder="Your Last Name"
											id="last_name"
											class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
											on:change={(e) => (error_name_last = "")}
											readonly="true"
										/>
									</div>
								</div>
								<div class="flex flex-col md:flex-row">
									<div class="w-full mx-2 flex-1 svelte-1l8159u">
										<div
											class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
										>
											Phone
										</div>
										<p
											class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
										>
											{mobile_country}-{mobile}
										</p>
									</div>
									<div class="w-full mx-2 flex-1 svelte-1l8159u">
										<div
											class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
										>
											Email
										</div>
										<input
											bind:value={email}
											id="email"
											placeholder="Your Email"
											type="email"
											class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
											on:change={(e) => (error_email = "")}
											readonly="true"
										/>
									</div>
								</div>
								<div class="flex flex-col md:flex-row">
									<div class="w-full mx-2 flex-1 svelte-1l8159u">
										<div
											class="font-bold text-gray-600 text-xs leading-8 capitalize h-6 mx-2 mt-3"
										>
											Contact Method
										</div>
										<p
											class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
										>
											{contact_method === "sms" ? "SMS" : "Email"}
										</p>
									</div>
								</div>

								<div class="flex p-2 mt-4">
									<button
										class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer
                      hover:bg-gray-200
                      bg-gray-100
                      text-gray-700
                      border duration-200 ease-in-out
                      border-gray-600 transition"
										on:click={(e) => {
											activeStep = 2;
											visited.confirm = false;
										}}>Previous</button
									>
									<div class="flex-auto flex flex-row-reverse">
										<button
											class="text-base ml-2 hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer
                        hover:bg-teal-600
                        bg-teal-600
                        text-teal-100
                        border duration-200 ease-in-out
                        border-teal-600 transition"
											on:click={(e) => moveNext(4, "review")}>Submit</button
										>
									</div>
								</div>
							</div>
						{/if}
						{#if activeStep === 4}
							<div class="mt-8 p-4">
								<div>
									<div class="flex flex-col md:flex-row">
										<div class="w-full flex-1 mx-2 svelte-1l8159u">
											<div
												class="font-bold text-gray-600 text-xl bold leading-8 capitalize h-6 mx-2 mt-3 text-center"
											>
												{#if !loading}
													{#if show_success}
														Message sent, we will contact you shortly! Thank
														you.
													{:else}
														Our apologies. Please check your internet and try
														again.
													{/if}
												{:else}
													please wait, while we are processing
												{/if}
											</div>
										</div>
									</div>
								</div>
							</div>
						{/if}
					</div>
				</div>
			</div>
			<div class="hidden sm:block pt-5">
				<div class="py-5">
					<div class="border-t border-gray-200" />
				</div>
			</div>
		{/if}
	</form>
</div>

<style>
</style>
