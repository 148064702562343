<script>
  // import { loadGooglePlacesLibrary } from "./loader.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { addy } from "./stores.js";
  // export let apiKey;
  export let options = {
        types: ["geocode"],
        componentRestrictions: { country: "AU" },
      };
  export let placeholder = undefined;
  export let value = "";
  const dispatch = createEventDispatcher();
  let inputField;
  $: selectedLocationName = value || "";
  onMount(() => {
    //loadGooglePlacesLibrary(apiKey, () => {
    const autocomplete = new google.maps.places.Autocomplete(
      inputField,
      options
    );

    autocomplete.addListener("place_changed", () => {
      let address = {};
      const place = autocomplete.getPlace();
      // console.log({ place });

      address.formatted_address = place.formatted_address;
      address._geoloc = {};
      address._geoloc.lat = place.geometry.location.lat();
      address._geoloc.lng = place.geometry.location.lng();
      address.place_id = place.place_id;
      address.utc_offset = place.utc_offset_minutes * 60; // 600 for chatswood

      let address_object = {
        addy: address,
        place: place.address_components,
        text: inputField.value
      };
      console.log('========================', address_object);
      dispatch('place_info', address_object)
      addy.set(address_object);
      // There are circumstances where the place_changed event fires, but we
      // were NOT given location data. I only want to propagate the event if we
      // truly received location data from Google.
      // See the `Type something, no suggestions, hit Enter` test case.
      if (hasLocationData(place)) {
        setSelectedLocation({
          place: place,
          text: inputField.value
        });
      }
    });

    dispatch("ready");
  });
  function emptyLocationField() {
    inputField.value = "";
    onChange();
  }
  function hasLocationData(place) {
    const fieldsToLookFor = (options && options.fields) || ["geometry"];
    return place.hasOwnProperty(fieldsToLookFor[0]);
  }
  function onChange() {
    if (inputField.value === "") {
      setSelectedLocation(null);
    }
  }
  function onKeyDown(event) {
    const suggestionsAreVisible = document.getElementsByClassName("pac-item")
      .length;

    if (event.key === "Enter" || event.key === "Tab") {
      if (suggestionsAreVisible) {
        const isSuggestionSelected = document.getElementsByClassName(
          "pac-item-selected"
        ).length;
        if (!isSuggestionSelected) {
          selectFirstSuggestion();
        }
      } else if (doesNotMatchSelectedLocation(inputField.value)) {
        setTimeout(emptyLocationField, 10);
      }
    } else if (event.key === "Escape") {
      setTimeout(emptyLocationField, 10);
    }

    if (suggestionsAreVisible) {
      if (event.key === "Enter") {
        /* When suggestions are visible, don't let an 'Enter' submit a form (since
         * the user is interacting with the list of suggestions at the time, not
         * expecting their actions to affect the form as a whole). */
        event.preventDefault();
      }
    }
  }
  function selectFirstSuggestion() {
    // Simulate the 'down arrow' key in order to select the first suggestion:
    // https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events
    const simulatedEvent = new KeyboardEvent("keydown", {
      key: "ArrowDown",
      code: "ArrowDown",
      keyCode: 40
    });
    inputField.dispatchEvent(simulatedEvent);
  }
  function setSelectedLocation(data) {
    selectedLocationName = (data && data.text) || "";
    dispatch("place_changed", data);
  }
  function doesNotMatchSelectedLocation(value) {
    return selectedLocationName !== value;
  }
</script>

<input
  bind:this={inputField}
  class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
  on:change={onChange}
  on:keydown={onKeyDown}
  {placeholder}
  bind:value= {value} />
